import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "title": "Where can I register to vote?",
  "description": null,
  "author": "Diego",
  "categories": ["where", "faqs"],
  "date": "2020-08-06T00:00:00.000Z",
  "featured": false,
  "tags": [],
  "relatedArticles": []
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`You go to the Georgia Government’s `}<a parentName="p" {...{
        "href": "https://www.mvp.sos.ga.gov/"
      }}>{`My Voter Page`}</a>{` to register or find out if you are registered!`}</p>
    <p>{`To register, just click on the register button on the front page.`}</p>
    <p>{`Otherwise, if you want to see if you're already registered, just login on the right with the MVP Login to check your status.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      